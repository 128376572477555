$(document).on('turbolinks:load', function() {
    $('#internetTypeContainer input[type="radio"]').change(sign_up_requests_update_controls);
    $('#addressStateSelector').change(sign_up_requests_update_controls);

    $('#internetPackagesContainer input[type="radio"]').on('change', function() {
        $('#internetPackageChoices input[type="radio"]').closest('label').removeClass('active');
        $('#internetPackageChoices input[type="radio"]:checked').closest('label').addClass('active');
    })

     $('#signUpForm button[type="submit"]').on('click', function() {
         $(this).prop('disabled', true);
         $(this).html('Submitting  <i class="fas fa-spinner fa-pulse"></i>');
     });
});

function check_service_area(city, zip)  {
    var url;

    if (zip.length > 0) {
      url = "/check-service-area?zip=" + zip;
    } else if (city.length > 0) {
      url = "/check-service-area?city=" + city;
    }

    $.ajax({
      url: url,
      type: "get",
      data: "",
      success: function(data) {
        if (data.error !== null && data.error !== undefined) {
          alert("We do not currently offer service in your area.");
          $('#servicePlans').addClass('d-none');
        } else {
          if (!data.fiber_service_area) {
            $('.fiber').addClass('d-none');
            $('.wireless').addClass('col-xl-4');
            $('.wireless').removeClass('col-xl-3');
          } else {
            $('.fiber').removeClass('d-none');
            $('.wireless').addClass('col-xl-3');
            $('.wireless').removeClass('col-xl-4');
          }

         // $('#servicePlans').removeClass('d-none');
          $('span#city').html(data.city);
        }
      }, 
      error: function(data) {
        alert("We do not currently offer service in your area.");
        $('#servicePlans').addClass('d-none');
      },
    });
}


function sign_up_requests_update_controls() {
    var server_path = $('#signUpForm').data('show-company-path');
    var form_data   = $('#signUpForm').serialize();
    var city = $('#sign_up_request_city').val();
    var zip = $('#sign_up_request_zip').val();
    var state = $('#addressStateSelector').find(':selected').val();
    $("#internetPackagesContainer").addClass("d-none");

    $.ajax({
        data:    form_data,
	      type:    'POST',
      	url:     server_path,
      	async:   true,
      	success: function(response) {
            var updatedPackages = $.parseHTML(response.form_packages_partial);


            $("#internetPackageChoices").html(response.form_packages_partial);

            if (state === "ID") {
              check_service_area(city, zip, updatedPackages);
            } else {
              $('.fiber').removeClass('d-none');
              $('.fiber').addClass('col-xl-3');
            }

            $("#internetPackagesContainer").removeClass("d-none");
            
            if (response.show_company_name) {
                $('#companyNameContainer').slideDown();
            } else {
                $('#companyNameContainer').slideUp();
            }

            if (response.show_internet_packages) {
                $('#internetPackagesContainer').slideDown();
            } else {
                $('#internetPackagesContainer').slideUp();
            }

        }
    });
}
