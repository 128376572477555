$(document).on('turbolinks:load', function() {
    var max_chars = 4;

    $('.max-char').keydown( function(e){
        if ($(this).val().length >= max_chars) { 
            $(this).val($(this).val().substr(0, max_chars));
        }
    });
    
    $('.max-char').keyup( function(e){
        if ($(this).val().length >= max_chars) { 
            $(this).val($(this).val().substr(0, max_chars));
        }
    });

    $('.numeric-input').on('keypress', function(e) {
        validate(e);
    })
    $('.numeric-input').bind('paste', function(e) {
        validate(e);
    })

    $('.togglePassword').on("click", function () {
        const elem = $(this).parent().find('.hidden-field');
        const type = elem.attr("type") === "password" ? "text" : "password";
        type === "password" ? $(this).find('.icon').removeClass('.fa-eye-slash').addClass('fa-eye') : $(this).find('.icon').removeClass('.fa-eye').addClass('fa-eye-slash')
        elem.attr("type", type); 
    });
});

function validate(evt) {
    var theEvent = evt || window.event;
  
    // Handle paste
    if (theEvent.type === 'paste') {
        key = event.clipboardData.getData('text/plain');
    } else {
    // Handle key press
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if( !regex.test(key) ) {
      theEvent.returnValue = false;
      if(theEvent.preventDefault) theEvent.preventDefault();
    }
  }