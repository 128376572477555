// Initializing Owl Carousel
$(document).on('turbolinks:load', function () {
    /* remove overflow on html set from toggling mobile menu */
    $('html').css('overflow-y', 'visible');

    var owl = $('.owl-carousel.internet-type-carousel');
    owl.owlCarousel({
        loop: true,
        dots: false,
        items: 1,
        nav: true,
        navSpeed: 750,
        responsive: {
            0: {
                navText: ["<img src='img/responsive/prev.svg'>", "<img src='img/responsive/next.svg'>"],
            },
            786: {
                navText: ["<img src='img/slider/prev.svg'>", "<img src='img/slider/next.svg'>"],
            }
        }
    });
    $('.residentialBtn').on('click', function () {
        owl.trigger('to.owl.carousel', [0, 750]);
    });
    $('.commercialBtn').on('click', function () {
        owl.trigger('to.owl.carousel', [1, 750]);
    });
    $('.agricultureBtn').on('click', function () {
        owl.trigger('to.owl.carousel', [2, 750]);
    });
    $('.owl-carousel.testimonial-carousel').owlCarousel({
        loop: true,
        dots: false,
        items: 1,
        nav: true,
        navText: ["<img src='img/slider/prev.svg'>", "<img src='img/slider/next.svg'>"]
    });
    $('.owl-carousel.residential-carousel').owlCarousel({
        loop: true,
        dots: false,
        items: 1,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
        nav: false,
        margin: 5,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: true
    });

    $('.form-select').on('change', function (e) {
        this.blur();
    });

    $('#next-btn').on("click", function () {
        $('.step-1').addClass('d-none');
        $('.step-2').removeClass('d-none');
    });

    $(".hamburger").on('click', function () {
        $(this).parents().find('.show-mobile-menu').removeClass('hidden');
        $('html').css('overflow-y', 'hidden');
    });

    $(".hamburger-cross").on('click', function () {
        $(this).parents().find('.show-mobile-menu').addClass('hidden');
        $('html').css('overflow-y', 'auto');
    });

    var addClass = function (el, className) {
        if (el.classList) {
            el.classList.add(className);
        } else {
            el.className += ' ' + className;
        }
    },

        hasClass = function (el, className) {
            return el.classList ?
                el.classList.contains(className) :
                new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
        },

        removeClass = function (el, className) {
            if (el.classList) {
                el.classList.remove(className);
            } else {
                el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        },

        updateSelectPlaceholderClass = function (el) {
            var opt = el.options[el.selectedIndex];
            if (hasClass(opt, "placeholder")) {
                addClass(el, "placeholder");
            } else {
                removeClass(el, "placeholder");
            }
        },

        selectList = document.querySelectorAll("select");

    //Simulate placeholder text for Select box
    for (var i = 0; i < selectList.length; i++) {
        var el = selectList[i];
        updateSelectPlaceholderClass(el);
        el.addEventListener("change", function () {
            updateSelectPlaceholderClass(this);
        });
    }


});

// Detect Element In Viewport Or Not For Image Background Section
function inViewport(element) {

    // Get the elements position relative to the viewport
    var bb = element.getBoundingClientRect();

    // Check if the element is outside the viewport
    // Then invert the returned value because you want to know the opposite
    return !(bb.top > innerHeight - (window.innerHeight / 1.7) || bb.bottom < (window.innerHeight / 1.8));
}
// Image Background Section
var myElement = document.querySelector('.img-bg-image .overlay');

// Listen for the scroll event
document.addEventListener('scroll', event => {

    // Check the viewport status
    if (myElement) {
        myElement.style.transition = 'opacity 750ms ease-in-out';

        if (inViewport(myElement)) {

            myElement.style.opacity = '1';

        } else {

            myElement.style.opacity = '0';

        }
    }

})



// CONTACT FORM SAVE
$('#saveForm').on('click', function () {

    var formData = $("#contactForm").serializeArray();

    $.ajax({
        type: "POST",
        data: formData,
        url: "config/contactSave.php",
        success: function (res) {

            // location.reload();
            window.location.href = "thankyou.html";
            console.log(res);
        },
        error: function (e) {
            console.log(e);
        }
    });


});

// SIGNUP FORM SAVE
$('#signupFormSubmit').on('click', function () {

    var formData = $("#signupForm").serializeArray();

    $.ajax({
        type: "POST",
        data: formData,
        url: "config/contactSave.php",
        success: function (res) {

            // location.reload();
            //   window.location.href="thankyou.html";


            $('#contact_id').val(res);

            // $('.step-1').addClass('d-none');
            $('.step-2').removeClass('d-none');

            $('.step-1').hide();
            $('.step-2').show();
            console.log(res);
        },
        error: function (e) {
            console.log(e);
        }
    });

});

// SIGNUP FORM SAVE TWO STEP
$('#signupSaveTwoStep').on('click', function () {

    var formData = $("#signupFormTwoStep").serializeArray();

    $.ajax({
        type: "POST",
        data: formData,
        url: "config/contactSave.php",
        success: function (res) {

            // location.reload();
            window.location.href = "thankyou.html";
            console.log(res);

        },
        error: function (e) {
            console.log(e);
        }
    });

});


