function init() {
    if ($('.file-input-custom').length) {

		$('body').on('change', 'input[type="file"]', function() {
            var allFiles = [];
            var MAX_UPLOAD_SIZE = 2000000;
            $("#file_validation_message").slideUp();

            if (!$(this).val()) {
                resetInput(this);
                return;
            }

            if ($(this).prop('files')[0].size > MAX_UPLOAD_SIZE) {
                fileSizeTooBig(this);
                return;
            }

            if (!validateSize($(this).prop('files')[0], MAX_UPLOAD_SIZE)) {
                var elem = $(this).prop('files')[0]
                fileSizeTooBig(elem);
                return;
            } 

            var input = $(this),
                numFiles = input.get(0).files ? input.get(0).files.length : 1,
                label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
            input.trigger('fileselect', [numFiles, label]);
        });

        $('form').on("submit", function(e) { 
            $("input[type='file']").prop('readonly',true);
            $(this).find("input[type='submit']").prop('readonly',true);
            $(this).find("input[type='submit']").val("Submitting...");
        });

        $('body').on('fileselect', ':file', function(event, numFiles, label) {
            
            var input = $(this).parents('.input-group').find('.file-display'),
                log = numFiles > 1 ? numFiles + ' files selected' : label;
    
            if (input.length && numFiles) {
                input.html(log);
            } else {
                input.html(`No files chosen.`);
            }
        });
	}
}

function fileSizeTooBig(element) {
    $("#file_validation_message .message").html("File upload size should be less than 2MB.");
    $("#file_validation_message").slideDown();
    resetInput(element);
}

function resetInput(element) {
    $(element).val("");
    $(element).parents('.input-group').find('.file-display').html('No files chosen.')
}

function validateSize(files, maxSize) {
    var totalSize = 0;
          
    for (var i = 0; i < files.length; i++) {     
      totalSize += files[i].size;

      if (totalSize > maxSize) {
          return false;
      }
    }

    return true;
}

// need to initialize file upload on javascript escape
init();

$(document).on('turbolinks:load', function() { 
    init();
});