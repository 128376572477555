// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'jquery'

require('./popper.min.js')
require('./bootstrap.bundle.min.js')
require('./owl.carousel.min.js')
require("./custom.js")
require("./sign_up_requests.js")
require("./contact_requests.js")
require("./acp.js");
require("./recaptcha.js.erb")
require("./file_upload.js");


Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "@fortawesome/fontawesome-pro/js/all.js"
import '../../stylesheets/application.scss';

require.context('../images', true)

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context('../images/', true)
const imagePath = (name) => images(name, true)
